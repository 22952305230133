/// <reference types="@emotion/react/types/css-prop" />
import {
  ContentModule,
  useAnimationStates,
  useParsedText,
  useShowId,
  useShowInstructions,
} from '@backstage-components/base';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
} from '@chakra-ui/react';
import {css, cx} from '@emotion/css';
import {useMachine} from '@xstate/react';
import {motion} from 'framer-motion';
import {useSubscription} from 'observable-hooks';
import {useCallback, useEffect, type FC} from 'react';
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import {ComponentDefinition, type SchemaType} from './OpenLoginDefinition';
import {
  OpenLoginMachine,
  OpenLoginSuccessEvent,
  OpenLoginSuccessEventName,
} from './OpenLoginMachine';

export type OpenLoginComponentDefinition = ContentModule<
  'OpenLogin',
  SchemaType
>;

const MotionFlex = motion(Flex);

export const OpenLoginComponent: FC<OpenLoginComponentDefinition> = (
  definition
) => {
  const {props} = definition;
  const {
    agreementText,
    animationStates,
    buttonLabel,
    emailInputPlaceholder,
    emailRequiredErrorMessage,
    nameInputPlaceholder,
    showAgreementCheckbox,
  } = props;
  const agreementTextContent = useParsedText(
    agreementText ??
      ComponentDefinition.defaultFieldData.agreementText ??
      'I agree to receive future communications'
  );
  // This is the connection to instructions in the backstage system
  const {observable, broadcast} = useShowInstructions(
    ComponentDefinition.instructions,
    definition
  );
  // If animation states are not supported the `activeVariant` and
  // `motionOptions` can be removed
  const motionOptions = useAnimationStates(
    observable,
    broadcast,
    animationStates
  );
  // React to animation state requests
  useSubscription(observable, {next: (instruction) => dispatch(instruction)});

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    const onOpenLoginSuccess = (e: OpenLoginSuccessEvent): void => {
      const {detail} = e;
      timeoutId = setTimeout(() => {
        broadcast({
          type: 'OpenLogin:on-success',
          meta: {
            showId: detail.showId,
            attendeeId: detail.attendee.id,
            attendeeEmail: detail.attendee.email,
            attendeeName: detail.attendee.name,
            attendeeTags: detail.attendee.tags.join(','),
          },
        });
      }, 50);
    };
    document.body.addEventListener(
      OpenLoginSuccessEventName,
      onOpenLoginSuccess
    );
    return () => {
      document.body.removeEventListener(
        OpenLoginSuccessEventName,
        onOpenLoginSuccess
      );
      clearTimeout(timeoutId);
    };
  }, [broadcast]);

  const showId = useShowId();
  const [state, dispatch] = useMachine(OpenLoginMachine, {
    context: {broadcast, showId},
  });

  const form = useForm({mode: 'onSubmit'});

  const {
    handleSubmit,
    setError,
    clearErrors,
    register,
    formState: {errors},
  } = form;
  const nameError = errors.name?.message;
  const emailError = errors.email?.message;

  useEffect(() => {
    if (state.matches('failure')) {
      setError('passCode', {
        message: state.context.reason?.includes('InvalidPublicPasscode')
          ? emailRequiredErrorMessage
          : state.context.reason,
      });
    } else {
      clearErrors('passCode');
    }
  }, [clearErrors, emailRequiredErrorMessage, setError, state]);

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      // Send the instruction to the state machine, which will trigger the
      // broadcast
      if (typeof data.email !== 'string' || typeof data.name !== 'string') {
        return;
      }

      broadcast({
        type: 'OpenLogin:verify',
        meta: {
          agreementAnswer: data.agreementAnswer === 'accepted',
          agreementText: agreementText ?? '__NOT_SUPPLIED__',
          email: data.email,
          showId,
          name: data.name,
          moduleId: definition.mid,
        },
      });
    },
    [agreementText, broadcast, definition.mid, showId]
  );

  // `definition.style` is how layouts pass size information.
  const styleClassName = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;
  return (
    <MotionFlex
      direction="column"
      className={cx(styleClassName, definition.mid, 'open-login-wrapper')}
      gap={6}
      alignItems="center"
      id={definition.id}
      {...motionOptions}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          className="open-login-form-container"
          w="21.875rem"
          gap={6}
          direction="column"
          pointerEvents={
            definition.config.scope !== 'attendee' ? 'none' : 'auto'
          }
        >
          <FormControl isInvalid={typeof nameError !== 'undefined'}>
            <InputGroup flexDirection="column" alignItems="center">
              <Input
                aria-label="full name"
                className="name"
                h="3.125rem"
                fontSize="0.875rem"
                isInvalid={typeof nameError !== 'undefined'}
                borderRadius="0.625rem"
                placeholder={nameInputPlaceholder}
                {...register('name', {required: true})}
              />
              <FormErrorMessage>
                {typeof nameError === 'string' ? nameError : ''}
              </FormErrorMessage>
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={typeof emailError !== 'undefined'}>
            <InputGroup flexDirection="column" alignItems="center">
              <Input
                aria-label="email"
                className="email"
                h="3.125rem"
                fontSize="0.875rem"
                isInvalid={typeof emailError !== 'undefined'}
                borderRadius="0.625rem"
                type="email"
                {...register('email', {required: true})}
                placeholder={emailInputPlaceholder}
              />
              <FormErrorMessage>
                {typeof emailError === 'string' ? emailError : ''}
              </FormErrorMessage>
            </InputGroup>
          </FormControl>
          {typeof agreementText === 'string' && showAgreementCheckbox && (
            <FormControl
              className="open-login-checkbox-input-group"
              flexDirection="row"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <CheckboxGroup>
                <Checkbox
                  id={`agreementAnswer-${definition.mid}`}
                  m={1}
                  role="checkbox"
                  className="agreementAnswer"
                  type="checkbox"
                  value="accepted"
                  {...register('agreementAnswer', {required: false})}
                >
                  <span className="agreementText">{agreementTextContent}</span>
                </Checkbox>
              </CheckboxGroup>
            </FormControl>
          )}
          <Button
            className="submit-button"
            type="submit"
            bg="black"
            color="white"
            fontSize="1rem"
            borderRadius="0.625rem"
            h="3.125rem"
            _hover={{bg: '#484848'}}
            _active={{bg: '#484848'}}
            isLoading={state.matches('pending')}
          >
            {buttonLabel}
          </Button>
        </Flex>
      </form>
    </MotionFlex>
  );
};
